import * as React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

// markup
const NotFoundPage = () => (
  <main>
    <Helmet title="Page not found - iqcode" />
    <h1>
      <span>404</span>
      {' '}
      - Page not found
    </h1>
    <p>
      We are sorry, the desired page was not found
    </p>
    <Link className="btn" to="/">Home page</Link>
  </main>
);

export default NotFoundPage;
